var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientSiteData && _vm.$can("update", "clients")
    ? _c(
        "div",
        [
          _c("b-media", { staticClass: "mb-2" }, [
            _c("h2", { staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(_vm.clientSiteData.sitename) + " ")
            ])
          ]),
          _c(
            "b-modal",
            {
              ref: "validationErrorModal",
              attrs: {
                id: "validation-error-modal",
                title: "Form Validation Error",
                "ok-only": "",
                "ok-title": "Close",
                "ok-variant": "primary"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function(ref) {
                      var ok = ref.ok
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                return ok()
                              }
                            }
                          },
                          [_vm._v(" Close ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2587765528
              )
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    staticStyle: { color: "#EA5455" },
                    attrs: { icon: "AlertTriangleIcon", size: "24" }
                  }),
                  _c("span", [
                    _vm._v(
                      "Please fill in all required fields before submitting the form."
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.onSubmit($event)
                            }
                          }
                        },
                        [
                          _c(
                            "b-tabs",
                            { on: { input: _vm.onTabChanged } },
                            [
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [
                                            _c("feather-icon", {
                                              attrs: { icon: "HomeIcon" }
                                            }),
                                            _c("span", [
                                              _vm._v("General Site Information")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    2494554986
                                  )
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site Name",
                                                "label-for": "sitename"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "sitename",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "sitename",
                                                              readonly: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .sitename,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "sitename",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.sitename"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1701675330
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client Name",
                                                "label-for":
                                                  "client_display_name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "client_display_name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "client_display_name",
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              readonly: true,
                                                              name:
                                                                "client_display_name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .client_display_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "client_display_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.client_display_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3449152898
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Address",
                                                "label-for": "address"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "address",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "address"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3711158692
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Unit",
                                                "label-for": "addressUnit"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "addressUnit" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "addressUnit"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .addressUnit,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "addressUnit",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.addressUnit"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1830132900
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "City",
                                                "label-for": "city"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "city",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "city"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .city,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "city",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.city"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1887734756
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Country",
                                                "label-for": "country"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "country",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              options:
                                                                _vm.countryOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              "input-id":
                                                                "country",
                                                              clearable: false
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .country,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "country",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.country"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1286211288
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "State / Province",
                                                "label-for": "province"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "province",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              options:
                                                                _vm.provinceOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              "input-id":
                                                                "province",
                                                              clearable: false
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .province,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "province",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.province"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2496402218
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Postal Code",
                                                "label-for": "postal"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "postal",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "postal"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .postal,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "postal",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.postal"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  40026212
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Phone",
                                                "label-for": "phone"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "phone",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              _c("cleave", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id: "phone",
                                                                  raw: false,
                                                                  options:
                                                                    _vm.options
                                                                      .phone,
                                                                  placeholder:
                                                                    "123 123 1234"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .clientSiteData
                                                                      .phone,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.clientSiteData,
                                                                      "phone",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "clientSiteData.phone"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3398400697
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } }),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Location Type",
                                                "label-for": "is_main_location"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "is_main_location"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                              attrs: {
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .is_main_location,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.clientSiteData,
                                                                    "is_main_location",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.is_main_location"
                                                              }
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " Main Office (Used for Billing) "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3948701223
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Status",
                                                "label-for": "is_active"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "is_active",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.statusOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "is_active"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .is_active,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "is_active",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.is_active"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2297191313
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Services",
                                                "label-for": "services"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "services" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                              attrs: {
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .services
                                                                    .guard,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .clientSiteData
                                                                      .services,
                                                                    "guard",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.services.guard"
                                                              }
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " Guard Services "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                              attrs: {
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .services
                                                                    .videomonitoring,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .clientSiteData
                                                                      .services,
                                                                    "videomonitoring",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.services.videomonitoring"
                                                              }
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " Video Monitoring Services "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                              attrs: {
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .services
                                                                    .videoretention,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .clientSiteData
                                                                      .services,
                                                                    "videoretention",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.services.videoretention"
                                                              }
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " Video Retention Services "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                              attrs: {
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .services
                                                                    .guardtour,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .clientSiteData
                                                                      .services,
                                                                    "guardtour",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.services.guardtour"
                                                              }
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " Guard Tour Services "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                              attrs: {
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .services
                                                                    .tenant,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .clientSiteData
                                                                      .services,
                                                                    "tenant",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.services.tenant"
                                                              }
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " Tenant Services "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                              attrs: {
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .services
                                                                    .privateinvestigation,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .clientSiteData
                                                                      .services,
                                                                    "privateinvestigation",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.services.privateinvestigation"
                                                              }
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " Private Investigation Services "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3655700419
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.clientSiteData.services.guard === true ||
                              _vm.clientSiteData.services.guardtour === true ||
                              _vm.clientSiteData.services.tenant === true
                                ? _c(
                                    "b-tab",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function() {
                                              return [
                                                _c("feather-icon", {
                                                  attrs: { icon: "HomeIcon" }
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "Security Services Settings"
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        3894507718
                                      )
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Client Type",
                                                    "label-for": "clienttype"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "clienttype"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.clienttypeOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  "input-id":
                                                                    "clienttype",
                                                                  clearable: false
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .clientSiteData
                                                                      .client_type,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.clientSiteData,
                                                                      "client_type",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "clientSiteData.client_type"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1243965550
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Region",
                                                    "label-for": "regionloc"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "regionloc"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.regionLocOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  "input-id":
                                                                    "regionloc",
                                                                  clearable: false
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .clientSiteData
                                                                      .region_loc,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.clientSiteData,
                                                                      "region_loc",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "clientSiteData.region_loc"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1968589591
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Billing Rate",
                                                    "label-for": "billrate"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: { name: "billrate" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "billrate"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .bill_rate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.clientSiteData,
                                                                        "bill_rate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.bill_rate"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2211607451
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Special Billing Rate",
                                                    "label-for":
                                                      "specialbillrate"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "specialbillrate"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "specialbillrate"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .special_bill_rate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.clientSiteData,
                                                                        "special_bill_rate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.special_bill_rate"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2899709540
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-2 mb-1" },
                                        [
                                          _c("b-col", [
                                            _c("h5", [
                                              _vm._v(
                                                "Location Mapping Information"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Latitude",
                                                    "label-for": "latitude"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "latitude",
                                                      rules:
                                                        "regex:^([+-]?([0-9]+\\.[0-9]+))$"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "latitude"
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.updateMap,
                                                                    keyup:
                                                                      _vm.updateMap
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .geofence
                                                                        .lat,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .geofence,
                                                                        "lat",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.geofence.lat"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2247677900
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Longitude",
                                                    "label-for": "longitude"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "longitude",
                                                      rules:
                                                        "regex:^([+-]?([0-9]+\\.[0-9]+))$"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "longitude"
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.updateMap,
                                                                    keyup:
                                                                      _vm.updateMap
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .geofence
                                                                        .lon,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .geofence,
                                                                        "lon",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.geofence.lon"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3719573515
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Distance (metres)",
                                                    "label-for": "distance"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "distance",
                                                      rules: "integer"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "distance"
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.updateMap,
                                                                    keyup:
                                                                      _vm.updateMap
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .geofence
                                                                        .distance,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .geofence,
                                                                        "distance",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.geofence.distance"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      158469893
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _vm.clientSiteData.address !== "" &&
                                          _vm.clientSiteData.city !== "" &&
                                          _vm.clientSiteData.province !== "" &&
                                          _vm.clientSiteData.country !== ""
                                            ? _c(
                                                "b-col",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: { md: "3" }
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "primary",
                                                        block: ""
                                                      },
                                                      on: {
                                                        click: _vm.lookupAddress
                                                      }
                                                    },
                                                    [_vm._v(" Lookup Address ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      typeof _vm.clientSiteData.geofence.lat !==
                                        "undefined" &&
                                      typeof _vm.clientSiteData.geofence.lon !==
                                        "undefined" &&
                                      typeof _vm.clientSiteData.geofence
                                        .distance !== "undefined" &&
                                      _vm.clientSiteData.geofence.lat !== "" &&
                                      _vm.clientSiteData.geofence.lon !== "" &&
                                      _vm.clientSiteData.geofence.distance !==
                                        ""
                                        ? _c(
                                            "b-row",
                                            {
                                              key: _vm.mapKey,
                                              ref: "theMap",
                                              staticClass: "mt-2"
                                            },
                                            [
                                              _c("b-col", [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "l-map",
                                                      {
                                                        ref: "myMap",
                                                        attrs: {
                                                          zoom: _vm.zoom,
                                                          center: _vm.center
                                                        }
                                                      },
                                                      [
                                                        _c("l-tile-layer", {
                                                          attrs: {
                                                            url: _vm.url
                                                          }
                                                        }),
                                                        _c("l-marker", {
                                                          attrs: {
                                                            "lat-lng":
                                                              _vm.markerLatLng
                                                          }
                                                        }),
                                                        _c("l-circle", {
                                                          attrs: {
                                                            "lat-lng":
                                                              _vm.circle.center,
                                                            radius:
                                                              _vm.circle.radius,
                                                            color:
                                                              _vm.circle.color
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.clientSiteData.services.tenant === true
                                ? _c(
                                    "b-tab",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function() {
                                              return [
                                                _c("feather-icon", {
                                                  attrs: { icon: "HomeIcon" }
                                                }),
                                                _c("span", [
                                                  _vm._v("Tenant Settings")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        702490746
                                      )
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Tenant URL",
                                                    "label-for": "tenanturl"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "tenanturl"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "tenanturl"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .tenant
                                                                        .url,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .tenant,
                                                                        "url",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.tenant.url"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1900400074
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Tenant Password",
                                                    "label-for":
                                                      "tenantpassword"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "tenantpassword"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "tenantpassword"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .tenant
                                                                        .password,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .tenant,
                                                                        "password",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.tenant.password"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3287604234
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Tenant Max Permits",
                                                    "label-for":
                                                      "tenantMaxPermits"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "tenantMaxPermits",
                                                      rules: "integer"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "tenantMaxPermits"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .tenant
                                                                        .max_permits,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .tenant,
                                                                        "max_permits",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.tenant.max_permits"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3394913525
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.clientSiteData.services.videomonitoring ===
                              true
                                ? _c(
                                    "b-tab",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function() {
                                              return [
                                                _c("feather-icon", {
                                                  attrs: { icon: "HomeIcon" }
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "Video Monitoring Services Settings"
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        2057565805
                                      )
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-2 mb-1" },
                                        [
                                          _c("b-col", { attrs: { md: "6" } }, [
                                            _c("h4", [_vm._v("Services")])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-for": "services"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: { name: "services" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .remotevideomonitoring,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "remotevideomonitoring",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.remotevideomonitoring"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Remote Video Monitoring "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .mobileappenabled,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "mobileappenabled",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.mobileappenabled"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Mobile App Enabled "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .voicetalkdown,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "voicetalkdown",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.voicetalkdown"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Voice Talk-Down "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .videoverification,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "videoverification",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.videoverification"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Video Verification "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .virtualguardtours,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "virtualguardtours",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.virtualguardtours"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Virtual Guard Tours "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .elevatormonitoring,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "elevatormonitoring",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.elevatormonitoring"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Elevator Monitoring "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .cloudanalytics,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "cloudanalytics",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.cloudanalytics"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Cloud Analytics "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .remoteconcierge,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "remoteconcierge",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.remoteconcierge"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Remote Concierge "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .sensoreventtrigger,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "sensoreventtrigger",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.sensoreventtrigger"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Sensor/Event Trigger "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .initialsiteprogramming,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "initialsiteprogramming",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.initialsiteprogramming"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Initial Site Programming "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2388321505
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [
                                              _vm._v("Artificial Intelligence")
                                            ]),
                                            _vm._v(
                                              " (WARNING: All sites require advanced analytics) "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Do you require AI Analytics?",
                                                    "label-for":
                                                      "vmsaianalytics"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsaianalytics"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.vmsaianalyticsOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  "input-id":
                                                                    "vmsaianalytics",
                                                                  clearable: false
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .clientSiteData
                                                                      .videomonitoringservices
                                                                      .aianalytics,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices,
                                                                      "aianalytics",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "clientSiteData.videomonitoringservices.aianalytics"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1494789299
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Type of AI used for Cameras? (Manufacturer or type)",
                                                    "label-for": "vmsaitype"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsaitype"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsaitype"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .vmsaitype,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "vmsaitype",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.vmsaitype"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3671598553
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [
                                              _vm._v("Event Contact List")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Name",
                                                    "label-for":
                                                      "vmsnewEventPersonName"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewEventPersonName"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewEventPersonName"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewEventPersonName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewEventPersonName = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewEventPersonName"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2174731676
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Phone",
                                                    "label-for":
                                                      "vmsnewEventPersonPhone"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewEventPersonPhone"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                [
                                                                  _c("cleave", {
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      id:
                                                                        "vmsnewEventPersonPhone",
                                                                      raw: false,
                                                                      options:
                                                                        _vm
                                                                          .options
                                                                          .phone,
                                                                      placeholder:
                                                                        "123 123 1234"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.vmsNewEventPersonPhone,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.vmsNewEventPersonPhone = $$v
                                                                      },
                                                                      expression:
                                                                        "vmsNewEventPersonPhone"
                                                                    }
                                                                  })
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1874300577
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Email",
                                                    "label-for":
                                                      "vmsnewEventPersonEmail"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewEventPersonEmail",
                                                      rules: "email"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewEventPersonEmail",
                                                                    type:
                                                                      "email"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewEventPersonEmail,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewEventPersonEmail = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewEventPersonEmail"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      459658302
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-1" },
                                        [
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "primary",
                                                        block: "",
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleAddEventPerson()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.vmsAddEventPersonBtnLable
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "secondary",
                                                        block: "",
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.resetVMSEventTypeForm()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Cancel ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-1" },
                                        [
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Items in the table below will be save when you click on the Save Changes button at the bottom of the form."
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-table",
                                        {
                                          ref: "eventPersonTable",
                                          staticClass: "position-relative",
                                          staticStyle: {
                                            "min-height": "10rem"
                                          },
                                          attrs: {
                                            items:
                                              _vm.clientSiteData
                                                .videomonitoringservices
                                                .eventPersons,
                                            responsive: "",
                                            fields:
                                              _vm.vmsEventPersonstableColumns,
                                            "primary-key": "itemID",
                                            "show-empty": "",
                                            "empty-text": ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "cell(itemID)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.itemID
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(eventPersonName)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .eventPersonName
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(eventPersonPhone)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatPhoneNumber(
                                                                data.item
                                                                  .eventPersonPhone
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(eventPersonEmail)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .eventPersonEmail
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(actions)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "b-dropdown",
                                                      {
                                                        attrs: {
                                                          variant: "link",
                                                          "no-caret": "",
                                                          right:
                                                            _vm.$store.state
                                                              .appConfig.isRTL
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "button-content",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "feather-icon",
                                                                    {
                                                                      staticClass:
                                                                        "align-middle text-body",
                                                                      attrs: {
                                                                        icon:
                                                                          "MoreVerticalIcon",
                                                                        size:
                                                                          "16"
                                                                      }
                                                                    }
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm.$can(
                                                          "update",
                                                          "clients"
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleEditEventPersonFillForm(
                                                                      data.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "EditIcon"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle ml-50"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Edit"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$can(
                                                          "delete",
                                                          "clients"
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDeleteEventPerson(
                                                                      data.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "TrashIcon"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle ml-50"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Delete"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            543823583
                                          )
                                        },
                                        [
                                          [
                                            _vm._v(
                                              " No Site found for this client "
                                            )
                                          ]
                                        ],
                                        2
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center justify-content-sm-start",
                                            attrs: { cols: "12", sm: "6" }
                                          }),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex align-items-right justify-content-right justify-content-sm-end",
                                              attrs: { cols: "12", sm: "6" }
                                            },
                                            [
                                              _c("b-pagination", {
                                                staticClass:
                                                  "mb-0 mt-1 mt-sm-0",
                                                attrs: {
                                                  "total-rows":
                                                    _vm.vmstotalEventPersons,
                                                  "per-page": _vm.vmsperPage,
                                                  "first-number": "",
                                                  "last-number": "",
                                                  "prev-class": "prev-item",
                                                  "next-class": "next-item"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prev-text",
                                                      fn: function() {
                                                        return [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "ChevronLeftIcon",
                                                              size: "18"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    },
                                                    {
                                                      key: "next-text",
                                                      fn: function() {
                                                        return [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "ChevronRightIcon",
                                                              size: "18"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1308952388
                                                ),
                                                model: {
                                                  value:
                                                    _vm.vmsEventPersonsCurrentPage,
                                                  callback: function($$v) {
                                                    _vm.vmsEventPersonsCurrentPage = $$v
                                                  },
                                                  expression:
                                                    "vmsEventPersonsCurrentPage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", { attrs: { md: "6" } }, [
                                            _c("h5", [
                                              _vm._v("Police Telephone Number")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmspolicetelnum"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                [
                                                                  _c("cleave", {
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      id:
                                                                        "vmspolicetelnum",
                                                                      raw: false,
                                                                      options:
                                                                        _vm
                                                                          .options
                                                                          .phone,
                                                                      placeholder:
                                                                        "123 123 1234"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .vmspolicetelnum,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .clientSiteData
                                                                            .videomonitoringservices,
                                                                          "vmspolicetelnum",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "clientSiteData.videomonitoringservices.vmspolicetelnum"
                                                                    }
                                                                  })
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2679575524
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", { attrs: { md: "6" } }, [
                                            _c("h5", [
                                              _vm._v("Fire Telephone Number")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsfiretelnum"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                [
                                                                  _c("cleave", {
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      id:
                                                                        "vmsfiretelnum",
                                                                      raw: false,
                                                                      options:
                                                                        _vm
                                                                          .options
                                                                          .phone,
                                                                      placeholder:
                                                                        "123 123 1234"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .vmsfiretelnum,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .clientSiteData
                                                                            .videomonitoringservices,
                                                                          "vmsfiretelnum",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "clientSiteData.videomonitoringservices.vmsfiretelnum"
                                                                    }
                                                                  })
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      384945956
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [
                                              _vm._v("Monitoring Schedule")
                                            ]),
                                            _vm._v(
                                              " (Please discrible the schedule for each day of monitoring. e.g. Monday - 6pm to 6am, Saturady - 24hrs) "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Monday",
                                                    "label-for":
                                                      "vmsschedulemon"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsschedulemon"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsschedulemon"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .schedule
                                                                        .monday,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .schedule,
                                                                        "monday",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.schedule.monday"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2688234243
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Tuesday",
                                                    "label-for":
                                                      "vmsscheduletues"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsscheduletues"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsscheduletues"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .schedule
                                                                        .tuesday,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .schedule,
                                                                        "tuesday",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.schedule.tuesday"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3964556707
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Wednesday",
                                                    "label-for":
                                                      "vmsschedulewed"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsschedulewed"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsschedulewed"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .schedule
                                                                        .wednesday,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .schedule,
                                                                        "wednesday",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.schedule.wednesday"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1759116251
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Thursday",
                                                    "label-for":
                                                      "vmsschedulethurs"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsschedulethurs"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsschedulethurs"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .schedule
                                                                        .thursday,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .schedule,
                                                                        "thursday",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.schedule.thursday"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      4059968067
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Friday",
                                                    "label-for":
                                                      "vmsschedulefri"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsschedulefri"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsschedulefri"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .schedule
                                                                        .friday,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .schedule,
                                                                        "friday",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.schedule.friday"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3786612707
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Saturday",
                                                    "label-for":
                                                      "vmsschedulesat"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsschedulesat"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsschedulesat"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .schedule
                                                                        .saturday,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .schedule,
                                                                        "saturday",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.schedule.saturday"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2822283460
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Sunday",
                                                    "label-for":
                                                      "vmsschedulesun"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsschedulesun"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsschedulesun"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .schedule
                                                                        .sunday,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices
                                                                          .schedule,
                                                                        "sunday",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.schedule.sunday"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1567145347
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", { attrs: { md: "6" } }, [
                                            _c("h5", [_vm._v("Time Zone")])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmstimezone"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsfiretelnum"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .vmstimezone,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "vmstimezone",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.vmstimezone"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2405557057
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [
                                              _vm._v(
                                                "Events Requiring Escalation / Actions"
                                              )
                                            ]),
                                            _vm._v(
                                              " (Please provide at least three examples of Events that require escalation. Example: Trespassing, Loitering) "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Event Type",
                                                    "label-for":
                                                      "vmseventTypesNewDesc"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmseventTypesNewDesc"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  ref:
                                                                    "newEventTypeEventTypeDescription",
                                                                  attrs: {
                                                                    id:
                                                                      "vmseventTypesNewDesc"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewEventTypeDescription,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewEventTypeDescription = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewEventTypeDescription"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      4261152197
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Actions Required",
                                                    "label-for":
                                                      "vmsactionsRequiredNewDesc"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsactionsRequiredNewDesc"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  ref:
                                                                    "newEventTypeActionsRequiredDescription",
                                                                  attrs: {
                                                                    id:
                                                                      "vmsactionsRequiredNewDesc"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewActionsRequiredDescription,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewActionsRequiredDescription = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewActionsRequiredDescription"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      4093792806
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-1" },
                                        [
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "primary",
                                                        block: "",
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleAddEventType()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.vmsAddEventTypeBtnLable
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "secondary",
                                                        block: "",
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.resetVMSEventTypeForm()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Cancel ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-1" },
                                        [
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Items in the table below will be save when you click on the Save Changes button at the bottom of the form."
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-table",
                                        {
                                          ref: "eventTypeTable",
                                          staticClass: "position-relative",
                                          staticStyle: {
                                            "min-height": "10rem"
                                          },
                                          attrs: {
                                            items:
                                              _vm.clientSiteData
                                                .videomonitoringservices
                                                .eventTypes,
                                            responsive: "",
                                            fields:
                                              _vm.vmsEventTypestableColumns,
                                            "primary-key": "itemID",
                                            "show-empty": "",
                                            "empty-text": ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "cell(itemID)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.itemID
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key:
                                                  "cell(eventTypeDescription)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .eventTypeDescription
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key:
                                                  "cell(actionsRequiredDescription)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .actionsRequiredDescription
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(actions)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "b-dropdown",
                                                      {
                                                        attrs: {
                                                          variant: "link",
                                                          "no-caret": "",
                                                          right:
                                                            _vm.$store.state
                                                              .appConfig.isRTL
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "button-content",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "feather-icon",
                                                                    {
                                                                      staticClass:
                                                                        "align-middle text-body",
                                                                      attrs: {
                                                                        icon:
                                                                          "MoreVerticalIcon",
                                                                        size:
                                                                          "16"
                                                                      }
                                                                    }
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm.$can(
                                                          "update",
                                                          "clients"
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleEditEventTypeFillForm(
                                                                      data.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "EditIcon"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle ml-50"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Edit"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$can(
                                                          "delete",
                                                          "clients"
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDeleteEventType(
                                                                      data.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "TrashIcon"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle ml-50"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Delete"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2913914698
                                          )
                                        },
                                        [
                                          [
                                            _vm._v(
                                              " No Site found for this client "
                                            )
                                          ]
                                        ],
                                        2
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center justify-content-sm-start",
                                            attrs: { cols: "12", sm: "6" }
                                          }),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex align-items-right justify-content-right justify-content-sm-end",
                                              attrs: { cols: "12", sm: "6" }
                                            },
                                            [
                                              _c("b-pagination", {
                                                staticClass:
                                                  "mb-0 mt-1 mt-sm-0",
                                                attrs: {
                                                  "total-rows":
                                                    _vm.vmstotalEventTypes,
                                                  "per-page": _vm.vmsperPage,
                                                  "first-number": "",
                                                  "last-number": "",
                                                  "prev-class": "prev-item",
                                                  "next-class": "next-item"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prev-text",
                                                      fn: function() {
                                                        return [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "ChevronLeftIcon",
                                                              size: "18"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    },
                                                    {
                                                      key: "next-text",
                                                      fn: function() {
                                                        return [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "ChevronRightIcon",
                                                              size: "18"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1308952388
                                                ),
                                                model: {
                                                  value:
                                                    _vm.vmsEventTypesCurrentPage,
                                                  callback: function($$v) {
                                                    _vm.vmsEventTypesCurrentPage = $$v
                                                  },
                                                  expression:
                                                    "vmsEventTypesCurrentPage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [_vm._v("Procedure")])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Procedure in case of Technical Problems",
                                                    "label-for":
                                                      "vmsTechProcedures"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsTechProcedures"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsTechProcedures"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .technicalProcedures,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "technicalProcedures",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.technicalProcedures"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      901718136
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Procedure in case of Site Down",
                                                    "label-for":
                                                      "vmsSiteDownProcedures"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsSiteDownProcedures"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsSiteDownProcedures"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .siteDownProcedures,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "siteDownProcedures",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.siteDownProcedures"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2772303185
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Procedure in case of Camera Down",
                                                    "label-for":
                                                      "vmsCameraDownProcedures"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsCameraDownProcedures"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsCameraDownProcedures"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .cameraDownProcedures,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "cameraDownProcedures",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.cameraDownProcedures"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      808216273
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [
                                              _vm._v("Devices Information")
                                            ]),
                                            _vm._v(
                                              " (Please provide details on your NVR / Cloud Porvider. Example HikVision NVR Model Number XXXXXX, Eagle Eye VMS) "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Device Description",
                                                    "label-for":
                                                      "vmsnewDeviceDescription"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewDeviceDescription"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDeviceDescription"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDeviceDescription,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDeviceDescription = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDeviceDescription"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2687800220
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "IP / Host",
                                                    "label-for":
                                                      "vmsnewDeviceIPhost"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsnewDeviceIPhost"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDeviceIPhost"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDeviceIPhost,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDeviceIPhost = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDeviceIPhost"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3331414716
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "1" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Port",
                                                    "label-for":
                                                      "vmsnewDevicePort"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsnewDevicePort"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDeviceIPhost"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDevicePort,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDevicePort = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDevicePort"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1845404
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Application Protocol",
                                                    "label-for":
                                                      "vmsnewDeviceAppProtocol"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewDeviceAppProtocol"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDeviceAppProtocol"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDeviceAppProtocol,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDeviceAppProtocol = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDeviceAppProtocol"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1030609724
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Username",
                                                    "label-for":
                                                      "vmsnewDeviceUsername"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewDeviceUsername"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDeviceUsername"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDeviceUsername,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDeviceUsername = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDeviceUsername"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      595965212
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Password",
                                                    "label-for":
                                                      "vmsnewDevicePassword"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewDevicePassword"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDevicePassword",
                                                                    type:
                                                                      "password"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDevicePassword,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDevicePassword = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDevicePassword"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      4132821869
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-1" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Admin Portal IP / host",
                                                    "label-for":
                                                      "vmsnewDeviceEdgeIPhost"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewDeviceEdgeIPhost"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDeviceEdgeIPhost"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDeviceEdgeIPhost,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDeviceEdgeIPhost = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDeviceEdgeIPhost"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1524261212
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Admin Portal Username",
                                                    "label-for":
                                                      "vmsnewDeviceEdgeUsername"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewDeviceEdgeUsername"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDeviceEdgeUsername"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDeviceEdgeUsername,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDeviceEdgeUsername = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDeviceEdgeUsername"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1376064892
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Admin Portal Password",
                                                    "label-for":
                                                      "vmsnewDeviceEdgePassword"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsnewDeviceEdgePassword"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewDeviceEdgePassword",
                                                                    type:
                                                                      "password"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewDeviceEdgePassword,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewDeviceEdgePassword = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewDeviceEdgePassword"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      4155661709
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-1" },
                                        [
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "primary",
                                                        block: "",
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleAddDevice()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.vmsAddDeviceBtnLable
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "secondary",
                                                        block: "",
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.resetVMSDeviceForm()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Cancel ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-1" },
                                        [
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Items in the table below will be save when you click on the Save Changes button at the bottom of the form."
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-table",
                                        {
                                          ref: "devicesTable",
                                          staticClass: "position-relative",
                                          staticStyle: {
                                            "min-height": "10rem"
                                          },
                                          attrs: {
                                            items:
                                              _vm.clientSiteData
                                                .videomonitoringservices
                                                .devices,
                                            responsive: "",
                                            fields: _vm.vmsDevicestableColumns,
                                            "primary-key": "itemID",
                                            "show-empty": "",
                                            "empty-text": ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "cell(itemID)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.itemID
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key:
                                                  "cell(eventTypeDescription)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .deviceDescription
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(deviceIPhost)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.iphost
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(port)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.port
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(appProtocol)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .appProtocol
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(actions)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "b-dropdown",
                                                      {
                                                        attrs: {
                                                          variant: "link",
                                                          "no-caret": "",
                                                          right:
                                                            _vm.$store.state
                                                              .appConfig.isRTL
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "button-content",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "feather-icon",
                                                                    {
                                                                      staticClass:
                                                                        "align-middle text-body",
                                                                      attrs: {
                                                                        icon:
                                                                          "MoreVerticalIcon",
                                                                        size:
                                                                          "16"
                                                                      }
                                                                    }
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm.$can(
                                                          "update",
                                                          "clients"
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleEditDeviceFillForm(
                                                                      data.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "EditIcon"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle ml-50"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Edit"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$can(
                                                          "delete",
                                                          "clients"
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDeleteDevice(
                                                                      data.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "TrashIcon"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle ml-50"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Delete"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2408937118
                                          )
                                        },
                                        [
                                          [
                                            _vm._v(
                                              " No Site found for this client "
                                            )
                                          ]
                                        ],
                                        2
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center justify-content-sm-start",
                                            attrs: { cols: "12", sm: "6" }
                                          }),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex align-items-right justify-content-right justify-content-sm-end",
                                              attrs: { cols: "12", sm: "6" }
                                            },
                                            [
                                              _c("b-pagination", {
                                                staticClass:
                                                  "mb-0 mt-1 mt-sm-0",
                                                attrs: {
                                                  "total-rows":
                                                    _vm.vmstotalEventTypes,
                                                  "per-page": _vm.vmsperPage,
                                                  "first-number": "",
                                                  "last-number": "",
                                                  "prev-class": "prev-item",
                                                  "next-class": "next-item"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prev-text",
                                                      fn: function() {
                                                        return [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "ChevronLeftIcon",
                                                              size: "18"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    },
                                                    {
                                                      key: "next-text",
                                                      fn: function() {
                                                        return [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "ChevronRightIcon",
                                                              size: "18"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1308952388
                                                ),
                                                model: {
                                                  value:
                                                    _vm.vmsEventTypesCurrentPage,
                                                  callback: function($$v) {
                                                    _vm.vmsEventTypesCurrentPage = $$v
                                                  },
                                                  expression:
                                                    "vmsEventTypesCurrentPage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [
                                              _vm._v("Internet Access")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Internet Access Type",
                                                    "label-for":
                                                      "vmsInternetAccessType"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsInternetAccessType"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.vmsInternetAccessTypeOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  "input-id":
                                                                    "vmsInternetAccessType",
                                                                  clearable: false
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .clientSiteData
                                                                      .videomonitoringservices
                                                                      .internetAccessType,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices,
                                                                      "internetAccessType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "clientSiteData.videomonitoringservices.internetAccessType"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3042183722
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Upload Speed (Mbps)",
                                                    "label-for":
                                                      "vmsUploadSpeed"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsUploadSpeed"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsUploadSpeed"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .uploadSpeed,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "uploadSpeed",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.uploadSpeed"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      270266129
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Internet Provider",
                                                    "label-for":
                                                      "vmsInternetProvider"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "vmsInternetProvider"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsInternetProvider"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .internetProvider,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "internetProvider",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.internetProvider"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3774355665
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [_vm._v("Camera Details")])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Number of Cameras Monitored",
                                                    "label-for":
                                                      "numCamerasMonitored"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "numCamerasMonitored"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "numCamerasMonitored"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientSiteData
                                                                        .videomonitoringservices
                                                                        .numCamerasMonitored,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .clientSiteData
                                                                          .videomonitoringservices,
                                                                        "numCamerasMonitored",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "clientSiteData.videomonitoringservices.numCamerasMonitored"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2769484121
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Camera Name",
                                                    "label-for":
                                                      "vmsnewCameraName"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "vmsnewCameraName"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "vmsnewCameraName"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.vmsNewCameraName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.vmsNewCameraName = $$v
                                                                    },
                                                                    expression:
                                                                      "vmsNewCameraName"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3899111644
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass: "mb-1",
                                                  attrs: {
                                                    name: "check-button",
                                                    switch: "",
                                                    inline: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.vmsNewIncludeAIDetection,
                                                    callback: function($$v) {
                                                      _vm.vmsNewIncludeAIDetection = $$v
                                                    },
                                                    expression:
                                                      "vmsNewIncludeAIDetection"
                                                  }
                                                },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      " Include AI Detection "
                                                    )
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      "(Typically required for outdoor cameras, can also be used indoors.)"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-1" },
                                        [
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "primary",
                                                        block: "",
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleAddCamera()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.vmsAddCameraBtnLable
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "secondary",
                                                        block: "",
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.resetVMSCameraForm()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Cancel ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-1" },
                                        [
                                          _vm.$can("update", "clients")
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Items in the table below will be save when you click on the Save Changes button at the bottom of the form."
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-table",
                                        {
                                          ref: "camerasTable",
                                          staticClass: "position-relative",
                                          staticStyle: {
                                            "min-height": "10rem"
                                          },
                                          attrs: {
                                            items:
                                              _vm.clientSiteData
                                                .videomonitoringservices
                                                .cameras,
                                            responsive: "",
                                            fields: _vm.vmsCamerasTableColumns,
                                            "primary-key": "itemID",
                                            "show-empty": "",
                                            "empty-text": ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "cell(itemID)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.itemID
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(cameraName)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .cameraName
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(includeAIDetection)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "b-badge",
                                                      {
                                                        staticClass:
                                                          "text-capitalize",
                                                        attrs: {
                                                          pill: "",
                                                          variant:
                                                            "light-" +
                                                            _vm.resolveIncludeAIDetectionVariant(
                                                              data.item
                                                                .includeAIDetection
                                                            )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .includeAIDetection
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(actions)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "b-dropdown",
                                                      {
                                                        attrs: {
                                                          variant: "link",
                                                          "no-caret": "",
                                                          right:
                                                            _vm.$store.state
                                                              .appConfig.isRTL
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "button-content",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "feather-icon",
                                                                    {
                                                                      staticClass:
                                                                        "align-middle text-body",
                                                                      attrs: {
                                                                        icon:
                                                                          "MoreVerticalIcon",
                                                                        size:
                                                                          "16"
                                                                      }
                                                                    }
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm.$can(
                                                          "update",
                                                          "clients"
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleEditCameraFillForm(
                                                                      data.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "EditIcon"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle ml-50"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Edit"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$can(
                                                          "delete",
                                                          "clients"
                                                        )
                                                          ? _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDeleteCamera(
                                                                      data.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "TrashIcon"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle ml-50"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Delete"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2107419966
                                          )
                                        },
                                        [
                                          [
                                            _vm._v(
                                              " No Site found for this client "
                                            )
                                          ]
                                        ],
                                        2
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center justify-content-sm-start",
                                            attrs: { cols: "12", sm: "6" }
                                          }),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex align-items-right justify-content-right justify-content-sm-end",
                                              attrs: { cols: "12", sm: "6" }
                                            },
                                            [
                                              _c("b-pagination", {
                                                staticClass:
                                                  "mb-0 mt-1 mt-sm-0",
                                                attrs: {
                                                  "total-rows":
                                                    _vm.vmstotalCameras,
                                                  "per-page": _vm.vmsperPage,
                                                  "first-number": "",
                                                  "last-number": "",
                                                  "prev-class": "prev-item",
                                                  "next-class": "next-item"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prev-text",
                                                      fn: function() {
                                                        return [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "ChevronLeftIcon",
                                                              size: "18"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    },
                                                    {
                                                      key: "next-text",
                                                      fn: function() {
                                                        return [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "ChevronRightIcon",
                                                              size: "18"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1308952388
                                                ),
                                                model: {
                                                  value:
                                                    _vm.vmsCamerasCurrentPage,
                                                  callback: function($$v) {
                                                    _vm.vmsCamerasCurrentPage = $$v
                                                  },
                                                  expression:
                                                    "vmsCamerasCurrentPage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [
                                            _c("feather-icon", {
                                              attrs: { icon: "HomeIcon" }
                                            }),
                                            _c("span", [_vm._v("Notes")])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1394949880
                                  )
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Admin Notes",
                                                "label-for": "adminnote"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "adminnote" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id: "adminnote",
                                                                rows: "5",
                                                                "max-rows": "6"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .notes_admin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.clientSiteData,
                                                                    "notes_admin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.notes_admin"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2453110798
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client Notes",
                                                "label-for": "clientnote"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "clientnote" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "clientnote",
                                                                rows: "5",
                                                                "max-rows": "6"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .notes_client,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.clientSiteData,
                                                                    "notes_client",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.notes_client"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2402909774
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Public Notes",
                                                "label-for": "publicnote"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "publicnote" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "publicnote",
                                                                rows: "5",
                                                                "max-rows": "6"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientSiteData
                                                                    .notes_public,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.clientSiteData,
                                                                    "notes_public",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "clientSiteData.notes_public"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  977327310
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                { staticClass: "mt-3" },
                                [
                                  _vm.$can("update", "clients")
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "primary",
                                                block: "",
                                                type: "submit"
                                              }
                                            },
                                            [_vm._v(" Save Changes ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                { staticClass: "mt-3" },
                                [
                                  _vm.$can("delete", "clients")
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "primary",
                                                block: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteClientSite()
                                                }
                                              }
                                            },
                                            [_vm._v(" Delete Site ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1276384351
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }